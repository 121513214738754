import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Grid from '@material-ui/core/Grid'

import { useIntl } from 'gatsby-plugin-intl'
import Button from '@material-ui/core/Button'


const useStyles = (isTitleHanging = false) =>
makeStyles((theme) => ({
  root: {
    fontFamily: `'Cormorant Garamond', serif`,
    textAlign: 'center',
    width: '100%',
    padding: theme.spacing(2),
    [theme.breakpoints.between('xs', 'md')]: {
      minHeight: '60vh',
    },
  },
  title: {
    marginTop: theme.spacing(5),
    marginBottom: theme.spacing(6),
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '3rem',
    textAlign: 'center',
    textTransform: 'uppercase',
    lineHeight: '.9',
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(3),
      fontSize: theme.typography.pxToRem(20),
    },
  },
  title2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.5rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 400,
    marginTop: theme.spacing(4),
    marginBottom: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      marginTop: theme.spacing(3),
      marginBottom: theme.spacing(1),
      fontSize: theme.typography.pxToRem(17),
    },
  },
  enum1: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(.1),
    marginLeft: theme.spacing(2),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  enum2: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: .9,
    textAlign: 'left',
    marginTop: theme.spacing(.5),
    marginBottom: theme.spacing(0),
    marginLeft: theme.spacing(4),
    [theme.breakpoints.between('xs', 'sm')]: {
      fontSize: theme.typography.pxToRem(12),
    },
  },
  description: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.5),
      marginBottom: theme.spacing(.8),
      fontSize: theme.typography.pxToRem(12),
    },
  },
  descriptionBold: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1.1rem',
    lineHeight: 1.1,
    textAlign: 'left',
    fontWeight: 600,
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
      marginTop: theme.spacing(1.6),
      marginBottom: theme.spacing(.9),
      fontSize: theme.typography.pxToRem(14),
    },
  },
  address: {
    fontFamily: `'Cormorant Garamond', serif`,
    fontSize: '1rem',
    lineHeight: 1.2,
    textAlign: 'left',
    marginTop: theme.spacing(.6),
    marginBottom: theme.spacing(.4),
    [theme.breakpoints.down('sm')]: {
      fontSize: theme.typography.pxToRem(14),
    },
  },
  addressBloc: {
    marginLeft: theme.spacing(2),
    marginBottom: theme.spacing(2),
  },
}))

export const LegalCookies: React.FC<any> = () => {
  const intl = useIntl()
  const classes = useStyles(false)()
  const CookieThough: any = (typeof window !== 'undefined' && window.CookieThough) ? window.CookieThough : undefined
  
  return (
    <Grid container justifyContent="center" className={classes.root}>
      <Grid item md={9} sm={10}>
        <Grid item sm={12} className={classes.title}>
          {intl.formatMessage({ id: 'link.legalCookies' })}
        </Grid>
        <Grid item sm={12} className={classes.description}>
          {intl.formatMessage({ id: 'legalCookies.description' })}
        </Grid>
        <Grid item sm={12} className={classes.title}>
          <Button 
            variant={'outlined'}
            onClick={() => CookieThough?.show()}
          >
            {intl.formatMessage({ id: 'legalCookies.essentials.button' })}
          </Button>
        </Grid>
        
      </Grid>
    </Grid>
  )
}

export default LegalCookies
